@import "styles/scss/globals";

.creatorWrapper {
  .creatorName {
    font-weight: 600;
    font-size: 20px;
  }

  .subscriptionSection {
    padding: 2.5rem 0;
    border-bottom: 1px solid #e5e3e8;

    .content {
      .title {
        font-size: 2rem;
        color: #0d122a;
        font-weight: 600;
      }

      .text {
        color: #928c9b;
        margin-top: 1.5rem;
        white-space: pre-wrap;
      }
    }
  }

  .aboutSection {
    padding: 3.5rem 0;
    border-bottom: 1px solid #e5e3e8;

    .imageWrap {
      img {
        position: relative;
        border-radius: 8px;
        display: block;
        margin: 0 auto;
        width: 100%;
        height: auto;
      }
    }

    .content {
      //margin-left: 1rem;
      //margin-top: 1rem;

      .title {
        color: #0d122a;
      }

      .text {
        color: #928c9b;
        font-size: 1rem;
        margin-top: 1.5rem;
        margin-bottom: 1.95rem;
        white-space: pre-wrap;
      }
    }

    .socialIconsWrap {
      margin-top: 0.75rem;
      //margin-left: 1rem;

      .singleIconWrap {
        margin-right: 1.25rem;
        display: inline-block;

        &:hover,
        &:focus {
          opacity: 0.6;
        }
      }

      .singleIcon {
        width: auto;
        max-width: 100%;
        width: 1.5rem;
      }

      .shareIcon {
        position: relative;
        transform: translateY(1px);
      }
    }
  }

  .podcastSection {
    padding: 3.5rem 0 0.5rem;
    border-bottom: 1px solid #e5e3e8;
    //margin-left: -15px;
    //margin-right: -15px;

    .title {
      color: #0d122a;
      //margin-bottom: 2rem;
      //padding: 0 15px;
    }
  }

  .donationSection {
    padding: 3.5rem 0;
    border-bottom: 1px solid #e5e3e8;
    //margin-left: -15px;
    //margin-right: -15px;

    .title {
      color: #0d122a;
      margin-bottom: 2rem;
      //padding: 0 15px;
    }

    .text {
      color: #928c9b;
      margin-top: 1.5rem;
      white-space: pre-wrap;
    }

    .donactionCard {
      margin-top: 1.5rem;
      border: 1px solid #e5e3e8;
      border-radius: 5px;
      padding: 1rem 1.5rem;

      .donationTypes {
        display: flex;
        gap: 1rem;
        justify-content: flex-end;

        .type {
          color: #928c9b;
          white-space: pre-wrap;
          cursor: pointer;

          &.active {
            font-weight: 600;
          }
        }
      }

      .donationPriceSection {
        display: flex;
        flex-wrap: wrap;
        gap: 1.5rem;

        .definedPrice {
          display: flex;
          justify-content: center;
          gap: 1.5rem;
          @include max(425){
            gap: 1.1rem;
          }
          .price {
            border: 1px solid #e5e3e8;
            border-radius: 50%;
            padding: 1rem;
            cursor: pointer;
            font-weight: 500;
            height: 60px;
            width: 60px;
            display: flex;
            justify-content: center;
            align-items: center;

            &.active {
              background-color: #0d122a;
              color: #fff;
            }
          }
        }

        .customPrice {

          input::-webkit-outer-spin-button,
          input::-webkit-inner-spin-button {
            -webkit-appearance: none;
          }

          .currency {
            position: absolute;
            top: 45%;
            right: 10%;
          }
        }
      }
    }
  }

  .newsletterSection {
    padding: 3.5rem 15px;
    border-bottom: 1px solid #e5e3e8;
    margin-left: -15px;
    margin-right: -15px;

    .title {
      color: #0d122a;
      margin-bottom: 2rem;
    }

    .newslettersWrapper {
      display: flex;
      flex-direction: column;
      gap: 1rem;

      .singleNewsletter {
        display: flex;
        justify-content: space-between;
        width: 100%;
        border: 1px solid #ced4da;
        border-radius: 5px;
        padding: 1rem;
        color: #0d122a;
        font-size: 1rem;
        text-decoration: none;

        &:hover {
          opacity: .65;
          text-decoration: none;
        }

        &::after,
        &::before {
          content: none;
        }
      }
    }
  }

  .tweetsSection {
    padding: 3.5rem 15px;
    border-bottom: 1px solid #e5e3e8;
    margin-left: -15px;
    margin-right: -15px;

    .title {
      color: #0d122a;
      margin-bottom: 2rem;
    }

    .allTweetsWrapper {
      .singleTweet {
        @include max(375) {
          padding-left: 0;
          padding-right: 0;
        }
      }
    }
  }
}